import React from 'react'
import './../styles/link.css'

export default function PrivacyPolicy() {
  return (
    <div id='wholebody'>
      <title>Privacy Policy</title>
      <center style={{textAlign:'justify', paddingLeft: '1rem', paddingRight: '1rem'}}>
        
        <div className='head'>
          <div className="heading">
            <span style={{color: 'rgba(247,111,50,1)'}}>Privacy</span> Policy
          </div>
          <center>
          <div className="date">
            <span style={{fontFamily: 'Gilroy-Medium', color: 'rgba(61, 61, 61, 1)'}}>
              Last Updated </span>
            <span style={{fontFamily: 'Gilroy-Bold', fontWeight: 400}}>
              March 07, 2023
            </span>
          </div>
          </center>
        </div>

        <div className='thirdsec'>
          <p className="commonfont">
              This privacy policy describes Our policies and procedures on the
                collection, use, and disclosure of Your information when You use the
                Service and tells You about Your privacy rights and how the law
                protects You.
          </p>

          <p className="commonfont">
            We use your personal data to provide and improve the service. By
            using the service, You agree to the collection and use of
            information in accordance with this privacy policy. This privacy
            policy has been created with the help of the
          </p>
        </div>

      <div className='2ndsec'>
      <div className="bsecond">Defintion</div>
      <p className="commonfont">For the purposes of this privacy policy:</p>
        <ul className="commonfont" style={{ paddingLeft:'2rem', color: 'rgba(61, 61, 61, 1)' }}>
          <li>
          An account is a unique account created for you to access our service or parts of our service.
          </li>
          <li>
          Affiliate means an entity that controls, is controlled by, or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for the election of directors or other managing authority
          </li>
          <li>
          Application refers to Froker, the software program provided by the
          company.
          </li>
          <li>
          Company (referred to as either &quot;the Company&quot;,
          &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this agreement)
          refers to Froker.
          </li>
          <li>
          Country refers to: Karnataka, India
          </li>
          <li>
          Device means any device that can access the service such as a
          computer, a cellphone or a digital tablet.
          </li>
          <li>
          Personal Data is any information that relates to an identified or
          identifiable individual.
          </li>
          <li>
          Service refers to the application.
          </li>
          <li>
          A service provider is any natural or legal person who processes the data on behalf of the company. It refers to third-party companies or individuals employed by the company to facilitate the service, to provide the service on behalf of the company, to perform services related to the service, or to assist the company in analysing how the service is used.
          </li>
          <li>
          Usage data refers to data collected automatically, either generated by the use of the service or from the service infrastructure itself (for example, the duration of a page visit).
          </li>
          <li>
          You means the individual accessing or using the service, or the company or other legal entity on behalf of which such individual is accessing or using the service, as applicable.
          </li>
        </ul>
       </div>

        <center>
          <div className="secondheading">
          <span style={{color: 'rgba(247,111,50,1)'}}>Collecting </span>
            <span style={{ color: 'rgba(61, 61, 61, 1)' }}>and Using Your </span><br></br>
            <span style={{color: 'rgba(247,111,50,1)'}}>Personal Data</span>
          </div>
        </center>

        
       <div className='thirdsec'> 
       <div className="bsecond">Personal Data: </div>
        <p className="commonfont">
          While using our service, We may ask you to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but
          is not limited to Usage Data
        </p> 

        <div className="bsecond">Usage Data </div>
        <ul className="commonfont" style={{ paddingLeft:'2rem', color: 'rgba(61, 61, 61, 1)' }}>
            
            <li>Usage Data is collected automatically when using the service.</li>

            <li>Usage Data may include information such as your device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our service that you visit, the time and date of your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.When you access the Service by or through a
            mobile device, We may collect certain information automatically,
            including, but not limited to, the type of mobile device You use,
            Your mobile device unique ID, the IP address of Your mobile device,
            Your mobile operating system, the type of mobile Internet browser
            You use, unique device identifiers and other diagnostic data.
            </li>

            <li>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.</li>

        </ul>
       </div>

        <div className='forthsec'>
        <div className="bsecond">Use of Your Personal Data</div>
        <span className="commonfont" style={{ fontFamily: 'Gilroy-SemiBold, sans-serif', color: 'rgba(0, 0, 0, 1)' }}>
            <b>The company may use personal data for the following purposes:</b>
        </span>
        <ul className="commonfont" style={{ paddingLeft:'2rem', color: 'rgba(61, 61, 61, 1)' }}>

          <li>
          To provide and maintain our service, including to monitor the usage of our service.
          </li>
          <li>
          <span style={{ fontFamily: 'Gilroy-SemiBold, sans-serif' }}>
              <b>To manage Your account:</b>
              </span>
              to manage your registration as a user of the service. The personal data you provide can give you access to different functionalities of the service that are available to you as a registered user.
          </li>
          <li>
            <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
              <b>For the performance of a contract:</b>
            </span>
            the development, compliance, and undertaking of the purchase contract for the products, items, or services you have purchased or of any other contract with us through the service.
          </li>
          <li>
          <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
              <b>To contact You:</b> 
            </span>
            To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products, or contracted services, including the security updates, when necessary or reasonable for their implementation.
          </li>
          <li>
          To provide you with news, special offers, and general information about other goods, services, and events that we offer that are similar to those that you have already purchased or inquired about, unless you have opted not to receive such information.
          </li>
          <li>
          <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
              <b>To manage Your requests:</b>
            </span>
            To attend and manage Your requests to us.
          </li>
          <li>
          <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
              <b>For business transfers:</b>
            </span>
            we may use your information to evaluate or conduct a merger, divestiture, restructuring, reorganisation, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal data held by us about our service users is among the assets transferred.
          </li>
          <li>
          <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
              <b>For other purposes:</b>
            </span>
            We may use your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and evaluating and improving our service, products, services, marketing, and your experience.
          </li>
        </ul>


        <span className="commonfont" style={{ fontFamily: 'Gilroy-SemiBold, sans-serif', color: 'rgba(0, 0, 0, 1)' }}>
          <b>We may share Your personal information in the following situations:</b>
        </span>
        <ul className="commonfont" style={{ paddingLeft:'2rem', color: 'rgba(61, 61, 61, 1)' }}>
        <li>
          <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
              <b>With Service Providers:</b>
            </span>
            We may share your personal information with service providers to monitor and analyse the use of our service and to contact you.
          </li>
          <li>
          <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
            <b>For business transfers:</b>
            </span>
             We may share or transfer your personal information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
          </li>
          <li>
          <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
              <b>With Affiliates:</b>
            </span>
            We may share your information with our affiliates, in which case we will require those affiliates to honour this privacy olicy. Affiliates include our parent company and any other subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.
          </li>
          <li>
          <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
             <b>With business partners:</b> 
            </span>
            We may share your information with our business partners to offer you certain products, services, or promotions.
          </li>
          <li>
          <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
              <b>With other users:</b>
            </span>
            When you share personal information or otherwise interact in public areas with other users, such information may be viewed by all users and may be publicly distributed outside.
          </li>
          <li>
          <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
              <b>With Your consent:</b>
            </span>
            We may disclose your personal information for any other purpose.
          </li>
        </ul>
      </div>


      <div className='retention'>
      <div className="bsecond">Retention of Your Personal Data</div>
      <p  className="commonfont">
          The company will retain your personal data only for as long as is necessary for the purposes set out in this privacy policy. We will retain and use your personal data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.

              <br />
              <span style={{lineHeight: '60px'}}>The</span> company will also retain usage data for internal analysis purposes. Usage data is generally retained for a shorter period of time, except when this data is used to strengthen the security or improve the functionality of our service, or we are legally obligated to retain this data for longer time periods.
            </p>
      </div>

      <div className='transfer'>
      <div className="bsecond">Transfer of Your Personal Data</div>
      <p className="commonfont">
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to comply
              with our legal obligations (for example, if we are required to
              retain your data to comply with applicable laws), resolve disputes,
              and enforce our legal agreements and policies.
              <br />
              <span>The Company will also retain
              Usage Data for internal analysis purposes. Usage Data is generally
              retained for a shorter period of time, except when this data is used
              to strengthen the security or to improve the functionality of Our
              Service, or We are legally obligated to retain this data for longer
              time periods.</span></p>
      </div>

      <div className='delete'>
      <div className="bsecond">Delete of Your Personal Data</div>
      <p className="commonfont">
              You have the right to delete or request that we assist in deleting the personal data that we have collected about you. Our service may give you the ability to delete certain information about you from within the service. You may update, amend, or delete your information at any time by signing in to your account, if you have one, and visiting the account settings section that allows you to manage your personal information. You may also contact us to request access to, correct, or delete any personal information that you have provided to us.
              <br />
              <span style={{lineHeight: '60px'}}>Please </span>
              note, however, that we may need to retain certain information when we have a legal obligation or lawful basis to do so.
            </p>
      </div>
      <div className='disclosure'>
      <div className="bsecond">Disclousure of Your Personal Data</div>
        <span className="commonfont" style={{ fontFamily: 'Gilroy-SemiBold, sans-serif', color: 'rgba(0, 0, 0, 1)' }}>
            <b>Business Transactions</b>
        </span>
        <ul className="commonfont" style={{ paddingLeft:'2rem', color: 'rgba(61, 61, 61, 1)' }}>
          <li>
          If the company is involved in a merger, acquisition, or asset sale, your personal data may be transferred. We will provide notice before your personal data is transferred and becomes subject to a different privacy policy.
          </li>
        </ul>

        <span className="commonfont" style={{ fontFamily: 'Gilroy-SemiBold, sans-serif', color: 'rgba(0, 0, 0, 1)' }}>
            <b>Law Enforcement</b>
        </span>
        <ul className="commonfont" style={{ paddingLeft:'2rem', color: 'rgba(61, 61, 61, 1)' }}>
        <li>
          Under certain circumstances, the company may be required to disclose your personal data if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).
          </li>
        </ul>

        <span className="commonfont" style={{ fontFamily: 'Gilroy-SemiBold, sans-serif', color: 'rgba(0, 0, 0, 1)' }}>
            <b>Other Legal Requirements</b>
        </span>
        <ul className="commonfont" style={{ paddingLeft:'2rem', color: 'rgba(61, 61, 61, 1)' }}>
          <li>The company may disclose your personal data in the good faith belief that such action is necessary to:</li>
            <div>
            <li>Comply with a legal obligation</li>
            <li>
              Protect and defend the rights or property of the company.
            </li>
            <li>
              Prevent or investigate possible wrongdoing in connection with the service.
            </li>
            <li>
              Protect the personal safety of Users of the Service or the
              public.
            </li>
            <li>Protect against legal liability.</li>  
            </div>         
        </ul>
      </div>
      
      <div className='Security'>
      <div className="bsecond">Security of Your Personal Data</div>
        <p className="commonfont">
        The security of your personal data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security.
        </p>
      </div>
      
      <div className='childrens'>
        <div className="bsecond">Children's Privacy</div>
        <p className="commonfont">
                Our service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with personal data, please contact us. If we become aware that we have collected personal data from anyone under the age of 13 without verification of parental consent, we will take steps to remove that information from our servers.
                <br />
                <span style={{paddingTop: '60px'}}>If we need to rely on consent as a legal basis for processing your information and your country requires consent from a parent, we may require your parent's consent before we collect and use that information.</span>
          </p>
      </div>

      <div className='links'>
      <div className="bsecond">Links to Other Websites</div>
      <p className="commonfont">
              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Privacy Policy of every site You visit.
              <br />
              <span style={{paddingTop: '50px'}}>We have no control over and
              assume no responsibility for the content, privacy policies or
              practices of any third party sites or services.</span> 
            </p>
      </div>

      <div className='changes'>
      <div className="bsecond">Changes to this Privacy Policy</div>
      <p className="commonfont">
        We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. We will let you know via email and/or a prominent notice on our service prior to the change becoming effective and update the
        &quot;Last updated&quot; date at the top of this privacy policy.
          <br />
          <span >You are </span> advised to review this privacy policy periodically for any changes. Changes to this privacy policy are effective when they are posted on this page.
        </p>
      </div>

      <div className='delivery'>
      <div className="bsecond">Delivery, Payment, Refund and Return Policy</div>
              <ul className="commonfont" style={{ paddingLeft:'2rem', color: 'rgba(61, 61, 61, 1)' }}>
                <li>
                  <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
                    <b>Guaranteed Delivery:</b>
                  </span>
                  You'll receive your ordered item within 4 hours from order
                  placement.
                </li>
                <li>
                  <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
                    <b>Delivery Confirmation:</b>
                  </span>
                  We'll provide confirmation once your order is successfully
                  delivered.
                </li>
                <li>
                  <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
                    <b>Timely Updates</b>
                  </span>
                  : Stay informed with real-time updates on your order's status
                  and expected delivery time.
                </li>
                <li>
                  <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
                    <b>Source of Refund:</b>
                  </span>
                  Refunds will be credited to the same account used for the
                  transaction.
                </li>
                <li>
                <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
                    <b>Refund Processing Time:</b>
                  </span>
                  Refunds will be credited to the same account used for the transaction.
                </li>
                <li>
                <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
                    <b>Item Issues:</b>
                  </span>
                  In case of any issues with the delivered item, refunds will be
                  processed within 3 business days after our team's approval.
                </li>
                <li>
                <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
                  <b>No Returns:</b> 
                  </span>
                  We regret to inform you that we do not accept returns for any
                  products purchased from our store.
                </li>
                <li>
                <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
                  <b>Quality Assurance:</b>
                  </span>
                  We are committed to providing you with high-quality products. If you receive a damaged or defective item, please contact our customer service within 1 hours of receiving the order, along with photographic evidence of the issue. We will assess the situation and provide a resolution accordingly.
                </li>
                <li>
                <span style={{fontFamily: 'Gilroy-SemiBold, sans-serif'}}>
                  <b>Order Cancellation:</b>
                  </span>
                  Orders cannot be cancelled or modified once they have been
                  placed and payment has been processed.
                </li>
              </ul>
      </div>
      </center>
    </div>
  )
}
