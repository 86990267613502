import React, { useEffect} from 'react';
import './../styles/frokercreator.css'
import Aos from 'aos';
import 'aos/dist/aos.css';

const Frokercreators = () => {

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
    <div  id="froker-creators" styel={{marginTop:'5rem'}}>
    </div>
    <div className="opportunity">
        <div className="adjust">
          <div className="opportunity-for-froker-s-creator">
            <span>
              <span className="opportunity-for-froker-s-creator-span">Opportunity For </span>
              <span className="opportunity-for-froker-s-creator-span2">
                Froker&#039;s Creator
              </span>
            </span>
          </div>
          <div className="your-subject-matter-expert-seamlessly-connecting-customers-with-merchants-by-catering-to-the-unique-tastes-of-each-user">
          Experience the thrill of a fun social platform to promote products, boost        
          </div>
          <div className="your-subject-matter-expert-seamlessly-connecting-customers-with-merchants-by-catering-to-the-unique-tastes-of-each-user">
           your affiliate earnings, and dive into exciting brand collaborations!
          </div>
          <div  className='your-subject-matter-expert-mobile'>
            Experience the thrill of a fun social platform to promote products, boost your affiliate earnings, and dive into exciting brand collaborations!
          </div>
        </div>
        <div className="three">
            <div data-aos="zoom-in" className="one">
                <img className="_11-1" src={'https://res.cloudinary.com/dapbrn8a9/image/upload/v1718957586/image_83_es94kt.png'} alt='img'/>
                <div className="hyperlocal-sme-s">
                    <span>
                      <span className="hyperlocal-sme-s-span2">Analytics</span>
                    </span>
                  </div>
                  <div className="frokers-are-experts-of-various-domains-and-operate-hyperlocally-to-generate-sales-through-content">
                  <div>Access detailed analytics for each</div>
                  <div>piece of content, allowing you to track </div>
                  <div>performance and optimize earnings</div>          
                  </div>                                  
            </div>
            <div data-aos="zoom-in" className="one">
                <img className="_11-1" src={'https://res.cloudinary.com/dapbrn8a9/image/upload/v1718957586/image_81_jbpgjp.png'} alt='img'/>
                <div className="hyperlocal-sme-s">
                    <span>
                      <span className="hyperlocal-sme-s-span2">Commission</span>
                    </span>
                </div>
                <div
                  className="frokers-are-experts-of-various-domains-and-operate-hyperlocally-to-generate-sales-through-content"
                >
                    
                <div>Earn top commissions by collaborating</div> 
                <div>twith diverse range of emerging</div> 
                <div>and established brands</div>                
              </div>                                  
            </div>
            <div data-aos="zoom-in" className="one">
                <img className="_11-1" src={'https://res.cloudinary.com/dapbrn8a9/image/upload/v1718957586/image_82_xliny6.png'} alt='img'/>
                <div className="hyperlocal-sme-s">
                    <span>
                      <span className="hyperlocal-sme-s-span2">Brand Collaboration </span>
                    </span>
                  </div>
                  <div
                  className="frokers-are-experts-of-various-domains-and-operate-hyperlocally-to-generate-sales-through-content">
               <div>Explore extensive brand collaboration</div> 
               <div>opportunities from our vast network</div> 
               <div>of brands on the Froker app</div>                
              </div>                                  
            </div>
        </div>
    </div></>
  );
};

export default Frokercreators;