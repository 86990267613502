import React, { useEffect } from 'react';
import "./../styles/merchants.css";
import Aos from 'aos';
import 'aos/dist/aos.css';

const Merchants = () => {

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
    <div  id="froker-merchants" styel={{marginTop:'5rem'}}>

    </div>
    <div className="all">
      <div className="opportunity-for-froker-s-merchant" >
      Maximize Your Brand’s Impact      
      </div>
      <div className="we-offer-merchants-a-platform-that-transcends">
      Discover a Platform Beyond Traditional Commerce<div>Transforms Business Growth</div> 
        <div> Unveils Innovative Marketing Strategies!</div>
      </div>
       
      <div className="lastbox">

        <div data-aos="zoom-in" className="lastone">
          <div className="minimum-order-commission">
            <span>
              <span className="minimum-order-commission-span">Campaign </span>
              <span className="minimum-order-commission-span2">Analytics</span>
            </span>
            <div className="our-model-charges-minimum-order-commissions">
            Gain deep insights with detailed campaign analytics,             
            </div>
            <div className="-significantly-lowering-selling-costs-and-boosting-profitability">
            ensuring transparent and effective brand promotion.            
            </div>
          </div>
          <img className="_17-1" src={'https://res.cloudinary.com/dapbrn8a9/image/upload/q_auto:low/v1706009704/Frokerassets/_17-10_cdrubo.png'} alt="17" />
        </div>

        <div data-aos="zoom-in" className="lasttwo">
          <img className="_18-1" src={'https://res.cloudinary.com/dapbrn8a9/image/upload/v1718956874/image_90_bvqs1e.png'} alt="18" />
          <div className="make">
            <div className="enhanced-brand-building">
              <span>
                <span className="enhanced-brand-building-span">Niche </span>
                <span className="enhanced-brand-building-span2">Targeting</span>
              </span>
            </div>
            <div className="collaborating-with-frokers-opens-new-channels-for">
            Access a curated pool of genuine creators tailored to             
            </div>
            <div className="brand-development-and-expanding-audience-reach">
            your specific needs and niche.            
            </div>
          </div>
        </div>

        <div data-aos="zoom-in" className="lastthree">
          <div className="makeb">
            <div className="ai-driven-saa-s-platform">
              <span>
                <span className="ai-driven-saa-s-platform-span">Affliate Marketing </span>
                <span className="ai-driven-saa-s-platform-span3">Opportunity</span>
              </span>
            </div>
            <div className="froker-s-ai-driven-saa-s-platform-is-a-game-changer-for-merchants">
            Enhance brand reach and sales through affiliate marketing.
            </div>
            <div className="offering-a-sophisticated-suite-of-tools-powered-by-generative-ai">
            Unlock the power of prompt-based marketing customized to            
            </div>
            <div className="offering-a-sophisticated-suite-of-tools-powered-by-generative-ai">
            align with your brand's ethos and strategy.            
            </div>
          </div>
          <img className="_19-1" src={'https://res.cloudinary.com/dapbrn8a9/image/upload/v1718956980/image_108_zpeqyn.png'} alt="19" />    
        </div>
      </div>
      </div>
    </>  
  );
};

export default Merchants;
