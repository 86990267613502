import React from 'react'
function BenifitsSM() {
  return (
    <div className='w-full h-full flex flex-col justify-between items-center  bg-[#FF5C0026]'>
        <div className='text-[#F76F32] font-Gilroy font-bold text-2xl md:text-3xl pt-6'>
            Benefits of a
        </div>
        <div className='text-[#F76F32] font-Gilroy font-bold text-2xl md:text-3xl'>
            Froshare Affiliate
        </div>
        <div className='font-Gilroy font-medium text-xl  md:text-2xl  text-[#3D3D3D] pt-3'>
            Unlock Your Revenue Streams
        </div>
        <div className="w-[80%] m-4 flex flex-col justify-center items-center border-[1px] border-black shadow-[5px_5px_0_0_#FD7A33] shadow-red p-4 rounded-2xl bg-white">{/*x,y,blur, spread */}
            <div className='w-1/2 h-1/2'>
                <img src={'https://res.cloudinary.com/dapbrn8a9/image/upload/v1712208492/CoinWallet_yhyv7v.png'} alt="CoinWallet"/>
            </div>
            <div className='text-[#F76F32] w-full pl-4 pr-4 flex flex-start font-Gilroy font-bold text-xl'>
                #1- Make <br/> Recommending Easy:
            </div>
            <div className='text-[#404040] text pl-4 pr-4 font-Gilroy font-normal text-md'>
            Customize your product recommendations effortlessly using <span className='text-[#000000] font-bold'>Auto links, Direct messages, and Auto-Reply</span> to connect personally with your audience.
            </div>
        </div>

        <div className="w-[80%] m-4 flex flex-col justify-center items-center border-[1px] border-black shadow-[5px_5px_0_0_#FD7A33] shadow-red p-4 rounded-2xl bg-white">{/*x,y,blur, spread */}
            <div className='w-1/2 h-1/2'>
                <img src={'https://res.cloudinary.com/dapbrn8a9/image/upload/v1712208267/Add_New_hze8hi.png'} alt="CoinWallet"/>
            </div>
            <div className='text-[#F76F32] w-full pl-4 pr-4 flex flex-start font-Gilroy font-bold text-xl'>
                #2 - Hunting for Brands?
            </div>
            <div className='text-[#404040] text pl-4 pr-4 font-Gilroy font-normal text-md'>
                <span className='text-[#000000] font-bold'>One-Stop Brand Hub,</span> with Multiple Brands, From established Favorites to Emerging Gems. Unlock <span className='text-[#000000] font-bold'>Incredible collaboration Opportunity,</span> All in One Place!      
            </div>
        </div>
        
        <div className="w-[80%] m-4 flex flex-col justify-center items-center border-[1px] border-black shadow-[5px_5px_0_0_#FD7A33] shadow-red p-4 rounded-2xl bg-white">{/*x,y,blur, spread */}
            <div className='w-1/2 h-1/2'>
                <img src={'https://res.cloudinary.com/dapbrn8a9/image/upload/v1712208267/NFT_wrktr1.png'} alt="CoinWallet"/>
            </div>
            <div className='text-[#F76F32] w-full pl-4 pr-4 flex flex-start font-Gilroy font-bold text-xl'>
                 #3 - Earn Big!
            </div>
            <div className='text-[#404040] w-full pl-4 pr-4 font-Gilroy font-normal text-md'>
                Your effort equals money! 
            </div>
            <div className='text-[#404040] w-full pl-4 pr-4 font-Gilroy font-normal text-md'>
                Receive <span className='text-[#000000] font-bold'> commission for every sale</span> you generate. 
            </div>
        </div>

        <div className="w-[80%] m-4 flex flex-col justify-center items-center border-[1px] border-black shadow-[5px_5px_0_0_#FD7A33] shadow-red p-4 rounded-2xl bg-white">{/*x,y,blur, spread */}
            <div className='w-1/2 h-1/2'>
                <img src={'https://res.cloudinary.com/dapbrn8a9/image/upload/v1712208266/Sell_g1xve4.png'} alt="CoinWallet"/>
            </div>
            <div className='text-[#F76F32] w-full pl-4 pr-4 flex flex-start font-Gilroy font-bold text-xl'>
                #4 - Tracking is important
            </div>
            <div className='text-[#404040] pl-4 pr-4 font-Gilroy font-normal text-md'>
            We'll <span className='text-[#000000] font-bold'>handle the complicated calculations;</span> you just focus on the numbers that count!"
            </div>
        </div>

    </div>
  )
}

export default BenifitsSM
