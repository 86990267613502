export const data = [
  {
    count: '01',
    heading: 'Sign Up on Froker app',
    description:'Sign Up using your contact number and personalize your profile for a tailored Froker experience',
    image: 'https://res.cloudinary.com/dapbrn8a9/image/upload/v1719645227/32_6382554_ysg563.png',
  },
  {
    count: '02',
    heading: 'Set Up Your Store',
    description:
      'Click on "Check out Now" on home screen, select your category and preferred brands to customize your store.',
    image: 'https://res.cloudinary.com/dapbrn8a9/image/upload/v1719644589/31_7728_v7ppsb.png',
  },
  {
    count: '03',
    heading: 'Tag & Share Product Links',
    description:'Tag products seamlessly and share product links with your followers effortlessly.',
      image: 'https://res.cloudinary.com/dapbrn8a9/image/upload/v1719645226/34_680492_rxhjam.png',
    },
  {
    count: '04',
    heading: 'Earn Commission',
    description:
      'Stay informed about your earnings and monitor performance through comprehensive analytical data.',
    image: 'https://res.cloudinary.com/dapbrn8a9/image/upload/v1719645226/33_1_ipjqti.png',
  },
];
